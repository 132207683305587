import { bindable, customElement, inject, noView } from "aurelia-framework";
import { render, unmountComponentAtNode } from "react-dom";
import React from "react";
import Offer from "./offer";
import { Client } from "../../api/client";

@noView
@inject(Element, Client)
@customElement("offer")
export default class AureliaOffer {
    @bindable() order;
    client = null;

    constructor(container, client) {
        this.container = container;
        this.client = client;
    }

    attached() {
        this.render();
    }

    bind() {
        this.attached();
    }

    orderChanged() {
        //Do not update on order change to avoid jumping view
        //this.detached();
        //this.attached();
    }

    async render() {
        const order = await this.client.get(`order/order/${this.order.id}`);
        render(<Offer order={order} />, this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
