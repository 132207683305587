import _ from "lodash";
import moment from "../../../../node_modules/moment-timezone/index";

const currencyMapping = {
    EUR: "€",
};

export const SECOND = 1;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const getDateStartTime = (date, dayStartTimes = {}, defaultDepartureTime) => {
    const _date = moment(date).format("YYYY-MM-DD");

    const [hours, minutes] = `${dayStartTimes[_date] ?? (defaultDepartureTime?.hours + ':' + defaultDepartureTime?.minutes)}`
        .split(":")
        .map((v) => parseInt(v, 10));
    const dateStartTime = moment(date).set({
        hour: hours,
        minute: minutes,
    });

    const offset = moment(date).toDate().getTimezoneOffset();

    //There are still some bugs with timezone handling
    return dateStartTime.add(-offset, "minutes");
};

export const formatPriceWithCommas = (price, currency) => {
    if (typeof price == "number") {
        price = price / 100; // transform from cent to euro
        const formatedPrice = price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const sign = _.get(currencyMapping, currency, "");
        return `${formatedPrice} ${sign}`;
    }
    return null;
};

export const getStartingTimeDict = (staringTimes = []) =>
    staringTimes.reduce(
        (acc, i) => ({
            ...acc,
            [i.date.split("T")[0]]: `${i.time.slice(
                0,
                i.time.length / 2
            )}:${i.time.slice(i.time.length / 2, i.time.length)}`,
        }),
        {}
    );
