import { useState, createContext, useEffect, useContext } from "react";
import { getGMapOptions } from "./google-maps-api";

export const GMapContext = createContext();

export const GMapContextProvider = ({ children }) => {
    const [options, setOptions] = useState({
        apiKey: "",
        language: "de",
    });
    const [map, setMap] = useState(null);

    useEffect(() => {
        const getOptions = async () => {
            const opts = await getGMapOptions();
            setOptions(opts);
        };
        getOptions();
    }, []);
    return (
        <GMapContext.Provider value={{ ...options, map, setMap }}>
            {children}
        </GMapContext.Provider>
    );
};

export const withGMapOptions = (Component) => ({ children, ...props }) => {
    const { language, apiKey } = useContext(GMapContext);
    return apiKey || props.apiKey ? (
        <Component language={language} apiKey={apiKey} {...props}>
            {children}
        </Component>
    ) : null;
};
