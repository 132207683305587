import {GOOGLE_MAP_KEY} from "../constants/index";
import {Container} from "aurelia-framework";
import {UserClient} from "../../../api/user-client";

// TODO: load from server gmap credentials
export const getGMapOptions = async () => {
    const client = Container.instance.get(UserClient);

    const apiKey = client.getUser().instance?.settings?.mapsInstanceSettings?.fallbackBackend;

    return Promise.resolve({
        apiKey: apiKey,
        language: "de",
    });
};
