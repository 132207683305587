import Moment from "moment";
import { extendMoment } from "moment-range";
import { EVENT_VIEW } from "../constants/index";
import { uniqBy } from "lodash";
const moment = extendMoment(Moment);

const sortEvents = (a, b) =>
    new Date(a.start).getTime() - new Date(b.start).getTime();

// TODO: refactoring

const createCalendarEvent = (event) => {
    const isAccommodation = event.provider?.includes("accommodation");
    const allDay = isAllDayEvent(event.fromDate, event.toDate, event.provider);
    return {
        start: event.fromDate,
        end: isAccommodation || !allDay ? event.toDate : moment(event.toDate).add(1, "day").format(),
        id: event.id,
        title: event.title,
        classNames: getEventCardColor(event),
        allDay: isAllDayEvent(event.fromDate, event.toDate, event.provider),
        extendedProps: {
            eventView: isAccommodation
                ? EVENT_VIEW.ACCOMMODATION
                : EVENT_VIEW.SERVICE,
            editable: true,
            startCoordinates: event?.startLocation,
            endCoordinates: event?.endLocation,
            currency: event.price.currency,
            name: event.title,
            optional: event.optional,
            cloneError: event.noPrice ?? false,
            id: event.id,
            isAccommodation,
            city: event.product?.address?.city,
            country: event.product?.address?.country,
            reference: event.data?.roomCategory,
            price: event.offerItemPriceWithOptionsPerPerson,
            ...({
                fare: event.data?.fare,
                roomCategory: event.data?.roomCategory,
                tariff: event.data?.tariff,
            }),
        },
    };
};

export const createDayStartEvent = (date) => ({
    start: date.format(),
    end: moment(date).add(1, "minutes").format(),
    classNames: ["calendar-wrapper--day-start-event"],
    id: `day-start-${date.format()}`,
    allDay: false,
    editable: false,
    display: "background",
    extendedProps: {
        eventView: EVENT_VIEW.DAY_START,
    },
});

export const isAllDayEvent = (eventDateStart, eventDateEnd, provider) => {
    return (
        eventDateStart?.includes("T00:00:00+00:00") ||
        eventDateStart === eventDateEnd ||
        (eventDateStart && !eventDateEnd) ||
        provider?.includes('ship')
    );
};

const getEventCardColor = ({ optional, provider }) => {
    let classNames = [""];
    if (!provider) {
        return classNames;
    }
    const key = provider.replace("/", "-");
    console.log("keys", key);

    classNames.push("item-tourism item-" + key + (optional ? " optional" : ""));

    return classNames;
};

const addAccommodationSurrogateEvents = (
    evs,
    allDayEvents,
    offerStartTime,
    offerEndTime
) => {
    const getOfferDates = () => {
        const range = moment.range(
            offerStartTime.startOf("date"),
            offerEndTime.startOf("date")
        );
        const dates = [...range.by("day")].map((d) => d.format());
        return dates;
    };
    const allEvents = [...evs, ...allDayEvents].sort(sortEvents);
    const offerDates = getOfferDates();
    // group events by dates
    // event belongs to date if time ranges intersects
    const datesWithEvents = offerDates.reduce((acc, date) => {
        const _events = allEvents.filter((e) => {
            const eStart = e.extendedProps.isAccommodation
                ? moment(moment(e.start).format("YYYY-MM-DDTHH:mm:ss")).startOf(
                      "date"
                  )
                : moment(e.start);
            const eEnd = e.extendedProps.isAccommodation
                ? moment(moment(e.end).format("YYYY-MM-DDTHH:mm:ss")).startOf(
                      "date"
                  )
                : moment(e.end);
            const eRange = moment.range(eStart, eEnd);
            const dateRange = moment.range(
                moment(date).startOf("date"),
                moment(date).endOf("date")
            );
            return dateRange.intersect(eRange);
        });
        return {
            ...acc,
            [date]: _events,
        };
    }, {});

    return uniqBy(
        Object.values(datesWithEvents)
            .flatMap((x) => x)
            .filter((x) => !x.allDay),
        "id"
    );
};

const prepareEventsForCalendar = async ({
    travelItems,
    wayPoints,
    calendarEvents,
    offerStartTime,
    offerEndTime
}) => {
    const { allDayEvents, commonEvents } = mergeEvents(calendarEvents).reduce(
        (acc, evt) => {
            evt.allDay
                ? acc.allDayEvents.push(evt)
                : acc.commonEvents.push(evt);
            return acc;
        },
        {
            allDayEvents: [],
            commonEvents: [],
        }
    );
    let events = addAccommodationSurrogateEvents(
        commonEvents,
        allDayEvents,
        offerStartTime,
        offerEndTime
    );

    let costEvents = {};

    (calendarEvents ?? []).forEach((calendarEvent, i) => {

        if (!calendarEvent.calculatedCostsEntries || calendarEvent.calculatedCostsEntries.length < 2) {
            return;
        }

        calendarEvent.calculatedCostsEntries.forEach((costItem, j) => {
            const classnames = ['calendar-wrapper--travel-event'];

            const fromDate = costItem.fromDate ?? costItem.from;
            const toDate = costItem.toDate ?? costItem.to;
            let title = costItem.title ?? costItem.reference.title;

            if (title?.['de']) {
                title = title.de;
            }

            const key = `${fromDate}_${toDate}_${costItem.reference?.id ?? 'noproduct'}`;

            costEvents[key] = {
                start: fromDate,
                end: toDate,
                id: 'cost-item-' + i + '-' + j,
                title: title + ' (Arrangement)',
                classNames: getEventCardColor({
                    provider: costItem?.reference?.modelId,
                    optional: calendarEvent.optional ?? false
                }),
                allDay: isAllDayEvent(fromDate, toDate, calendarEvent?.provider),
                extendedProps: {
                    eventView: EVENT_VIEW.SERVICE,
                    editable: false,
                    name: title,
                    optional: calendarEvent.optional ?? false,
                },
            };
        });
    });

    costEvents = Object.values(costEvents);

    (travelItems ?? []).forEach((travelItem, i) => {

        const classnames = ['calendar-wrapper--travel-event'];

        if (travelItem.bufferTime < 0) {
            classnames.push('lack-of-time');
        }

        events.push({
            id: 'travel-item-' + i,
            allDay: false,
            classNames: classnames,
            editable: false,
            start: travelItem.fromDate,
            end: travelItem.toDate,
            extendedProps: {
                eventView: EVENT_VIEW.TRAVEL,
                distance: travelItem.distance,
                duration: travelItem.duration,
                drivingTime: travelItem.drivingTime,
                bufferTime: travelItem.bufferTime,
                serviceTime: travelItem.serviceTime,
                breakTime: travelItem.breakTime,
            }
        })
    });

    (wayPoints ?? []).forEach((routingPoint, i) => {

        if (!routingPoint.visible) {
            return;
        }

        events.push({
            id: 'routing-item-' + i,
            allDay: false,
            classNames: ['calendar-wrapper--home-event'],
            editable: false,
            start: routingPoint.fromDate,
            end: routingPoint.toDate,
            title: routingPoint.title,
            description: 'Test',
            extendedProps: {
                eventView: EVENT_VIEW.ACCOMMODATION_SURROGATE,
            }
        })
    });

    events = [...allDayEvents, ...events, ...costEvents].sort(sortEvents);
    return events;
};

export const createMergedEvent = (evts) => {
    if (!evts.length) return null;
    const evt = createCalendarEvent(evts[0]);
    evt.extendedProps.mergedEvents = evts.map((e) => e.id);
    return evt;
};

export const mergeEvents = (evts) => {
    const noProduct = "no-product";

    const grouped = {};

    for (let evt of evts) {

        if (evt.b2bGroup || evt.tempB2bGroupMatchingCode) {

            //We sort here into groups where from offer groups are combined, but only as long as it does not exist in the group so far
            //So duplicate services are still displayed two times
            const key = `${evt.fromDate}_${evt.toDate}_${evt.product?.id ?? noProduct}`;
            let index = 0;

            while (1) {

                let currentKey = key + '-' + index;

                if (!grouped[currentKey]) {
                    grouped[currentKey] = [evt];
                    break;
                }

                let hasB2bGroup = false;

                for (let subEvt of grouped[currentKey]) {

                    let groupMatchingCodeA = subEvt.tempB2bGroupMatchingCode ?? subEvt.b2bGroup.id;
                    let groupMatchingCodeB = evt.tempB2bGroupMatchingCode ?? evt.b2bGroup.id;

                    if (groupMatchingCodeA === groupMatchingCodeB) {
                        hasB2bGroup = true;
                        break;
                    }
                }

                if (hasB2bGroup) {
                    index++;
                    continue;
                }

                grouped[currentKey].push(evt);
                break;
            }

        } else {
            grouped[evt.id] = [evt];
        }
    }

    console.log('GROUPED', grouped);

    return Object.keys(grouped).reduce((acc, key) => {
        const _evts =
            grouped[key].length <= 1
                ? grouped[key].map(createCalendarEvent)
                : [createMergedEvent(grouped[key])].filter(Boolean);
        acc.push(..._evts);
        return acc;
    }, []);
};

export const isMergedEvent = (
    evt,
    getter = (e) => e?.extendedProps?.mergedEvents
) => {
    const evtIds = getter?.(evt) ?? evt?.extendedProps?.mergedEvents;
    return evtIds && Array.isArray(evtIds) && evtIds.length > 0;
};

export default prepareEventsForCalendar;
