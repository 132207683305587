import moment from "moment-timezone";

export const getFirstAndLastDate = (item, firstDate, lastDate) => {
    if (item.fromDate) {
        const fromDate = moment(item.fromDate);

        if (!firstDate || fromDate < firstDate) {
            firstDate = fromDate;
        }

        if (!lastDate || fromDate > lastDate) {
            lastDate = fromDate;
        }
    }
    if (item.toDate) {
        const toDate = moment(item.toDate);

        if (!lastDate || toDate > lastDate) {
            lastDate = toDate;
        }
    }
    return { firstDate, lastDate };
};
