
export const getOrderItemUrl = (order) => {
    if (order.modelId !== 'order/order') {
        return `tourism-journey-calculation/configurator/items/${order.id}`;
    } else {
        return `order/order-item?embeds[]=product&embeds[]=calculatedCostsEntries.reference&sort[0][0]=sort&sort[0][1]=ASC&conditions[order]=${order.id}`;
    }
}

export const getOrderRouting = (order) => {
    if (order.modelId !== 'order/order') {
        return `tourism-journey-calculation/configurator/routing/${order.id}`;
    } else {
        return `order/order/routing/${order.id}`;
    }
}

export const getDeleteOrderItemUrl = (order, id) => {
    if (order.modelId !== 'order/order') {
        return `tourism-journey-calculation/configurator/delete/${order.id}/${id}`;
    } else {
        return `order/order-item/${id}`;
    }
}

export const getAddOrderItemUrl = (order) => {
    if (order.modelId !== 'order/order') {
        return `tourism-journey-calculation/configurator/add/${order.id}`;
    } else {
        return `order/order-item-add/trigger/${order.id}`;
    }
}

export const getOrderItemOptionalUrl = (id) =>
    `order-offer/set-order-item-optional/${id}`;

export const getOrderItemMoveUrl = (order, id) => {
    if (order.modelId !== 'order/order') {
        return `tourism-journey-calculation/configurator/move/${order.id}/${id}`;
    } else {
        return `order-item/move/${id}`;
    }
}
